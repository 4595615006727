import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/github/workspace/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/github/workspace/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/github/workspace/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/github/workspace/src/app/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/github/workspace/src/components/ui/sonner.tsx");
